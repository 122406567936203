// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boodwood-js": () => import("./../src/pages/boodwood.js" /* webpackChunkName: "component---src-pages-boodwood-js" */),
  "component---src-pages-design-js": () => import("./../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-diamondpearl-js": () => import("./../src/pages/diamondpearl.js" /* webpackChunkName: "component---src-pages-diamondpearl-js" */),
  "component---src-pages-dp-js": () => import("./../src/pages/dp.js" /* webpackChunkName: "component---src-pages-dp-js" */),
  "component---src-pages-fabricate-js": () => import("./../src/pages/fabricate.js" /* webpackChunkName: "component---src-pages-fabricate-js" */),
  "component---src-pages-fan-js": () => import("./../src/pages/fan.js" /* webpackChunkName: "component---src-pages-fan-js" */),
  "component---src-pages-hana-js": () => import("./../src/pages/hana.js" /* webpackChunkName: "component---src-pages-hana-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-int-js": () => import("./../src/pages/int.js" /* webpackChunkName: "component---src-pages-int-js" */),
  "component---src-pages-kids-js": () => import("./../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-regen-js": () => import("./../src/pages/regen.js" /* webpackChunkName: "component---src-pages-regen-js" */),
  "component---src-pages-sound-js": () => import("./../src/pages/sound.js" /* webpackChunkName: "component---src-pages-sound-js" */),
  "component---src-pages-treelamp-js": () => import("./../src/pages/treelamp.js" /* webpackChunkName: "component---src-pages-treelamp-js" */),
  "component---src-pages-wave-js": () => import("./../src/pages/wave.js" /* webpackChunkName: "component---src-pages-wave-js" */)
}

